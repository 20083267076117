<template>
    <router-link to="/">
        <img class="header-logo" src="/img/creaal-logo.png" height="104" alt="creal logo" />
    </router-link>
</template>

<style>
.header-logo {
    padding: 34px 30px 34px 30px;
    background-color: #fff;
    box-shadow: 0 11px 0px 0px white, 0 -11px 0px 0px white, 1px 0 16px -5px rgba(133, 133, 133, 0.8), -1px 0 1px -4px rgba(0, 0, 0, 0.8);
}
</style>