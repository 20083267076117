<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <img src="/img/footer-logo.jpg" />
                    <div class="adress">
                        Creaal BV<br />
                        Bergweidedijk 18<br />
                        7418 AA Deventer <br />
                        {{ $t('footer.netherlands') }}<br />
                    </div>
                </div>
                <div class="links col-lg-6 col-12">
                    <div class="row">
                        <div class="col-lg-4 col-12">
                            <div class="menu">
                                <h2>{{ $t('footer.links') }}</h2>
                            
                                <router-link to="/">{{ $t('menu.home') }}</router-link><br />
                                <router-link to="/labels">{{ $t('menu.labels') }}</router-link><br />
                                <router-link to="/over-ons">{{ $t('menu.about') }}</router-link><br />
                                <router-link to="/contact">{{ $t('menu.contact') }}</router-link>
                            </div>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div class="menu">
                                <h2>{{ $t('footer.information') }}</h2>
                            
                                <router-link to="/disclaimer">{{ $t('menu.disclaimer') }}</router-link><br />
                                <router-link to="/privacy-statement">{{ $t('menu.privacy-policy') }}</router-link><br />
                                <router-link to="/sitemap">{{ $t('menu.sitemap') }}</router-link><br />
                            </div>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div class="menu">
                                <h2>{{ $t('footer.contact') }}</h2>
        
                                <a class="phone" href="tel:+31854852403">+31 (0)85 4852403</a><br />
                                <a class="mail" href="mailto:info@creaal.nl">info@creaal.nl</a><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rights">
            <span>&copy; Creaal {{new Date().getFullYear()}} | All Rights Reserved</span>
        </div>
    </footer>
</template>

<script>
export default {
  components: {
  },
}
</script>

<style>
footer {
    position: relative;
    padding-bottom: 100px;
    overflow: hidden;
    background-color: #1e154a;
    color: #e5e4ed;
}
footer a, footer h1, footer h2 {
    color: #e5e4ed;
}
.adress {
    margin-top: 60px;
}
.rights {
    position: absolute;
    bottom: 0px;    
    background-color: #140c3e;
    width: 100%;
    height: 50px;
}
.rights span {
    color: #4f496e;
    margin-left: 310px;
    line-height: 55px;
}
.links {
    margin-top: 45px !important;
}
.menu h2 {
    color: #fff;
    font-size: 22px;
    margin-bottom: 20px;
}
.menu a {
    text-decoration: none;
}
.menu a:hover {
    color: #9f8053;
}
@media (max-width: 991px) {
    footer img {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: auto;
    }
    footer {
        text-align: center;
    }
    footer .menu {
        margin-bottom: 20px;
    }
    .rights span {
        margin-left: 0px;
    }
}
@media (max-width: 575px) {
    footer img {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: auto;
    }
    footer {
        text-align: center;
    }
    footer .menu {
        margin-bottom: 20px;
    }
    .rights span {
        margin-left: 0px;
    }
}
</style>