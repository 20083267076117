<template>
    <header>
        <nav v-bind:class="{'show-language-options': showLanguageOptions }">
            <div class="container top-header d-flex flex-row">
                <CreaalLogo />
                <div style="width: 100%; height: 115px;">
                    <div class="top-header-phone">
                        <div class="mt-1 p-2 px-5 pl-lg-2 d-none d-md-flex">
                            <a class="phonenumber-icon icon-phone pt-1" href="tel:+31570745622"></a><a class="phonenumber" href="tel:+31570745622">+31 (0)85 4852403</a>
                        </div>
                        <button @click="showNavbar == true ? showNavbar = false : showNavbar = true;" class="navbar-toggler mt-1" style="float:right" type="button">
                            <span class="navbar-toggler-icon">
                                <div class="d-none">Toggle</div>
                            </span>
                        </button>
                    </div>
                    <div class="top-header-nav d-none d-md-flex px-5 pr-md-0">
                        <router-link to="/">{{ $t('menu.home') }}</router-link>
                        <router-link to="/labels">{{ $t('menu.labels') }}</router-link>
                        <router-link to="/over-ons">{{ $t('menu.about') }}</router-link>
                        <router-link to="/contact">{{ $t('menu.contact') }}</router-link>
                 
                        <router-link to="/blogs">{{ $t('menu.blog') }}</router-link>
                        <div class="d-none d-md-block flex-lg-fill align-self-endd">
                            <div class="main-menu-translate">
                                <div class="language-button" @click.stop="toggleLanguage()">
                                    <a href="#">{{ $t('common.language') }} <i class="fas fa-chevron-down"></i></a>
                                </div>
                                <div class="language-options d-flex flex-column" :class="{'d-none' : !showLanguageOptions}">
                                    <a class="locale-nl" @click.stop="switchLanguage('nl')">Nederlands</a>
                                    <a class="locale-en" @click.stop="switchLanguage('en')">English</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container top-header-nav p-5 pt-0 d-flex flex-column d-lg-none" :class="{'d-none' : !showNavbar}">
                <router-link to="/" @click="showNavbar = false">{{ $t('menu.home') }}</router-link>
                <router-link to="/labels" @click="showNavbar = false">{{ $t('menu.labels') }}</router-link>
                <router-link to="/over-ons" @click="showNavbar = false">{{ $t('menu.about') }}</router-link>
                <router-link to="/contact" @click="showNavbar = false">{{ $t('menu.contact') }}</router-link>
                <router-link to="/blogs" @click="showNavbar = false">{{ $t('menu.blog') }}</router-link>

                <div class="main-menu-translate">
                    <div class="language-button" @click.stop="toggleLanguage()">
                        <b>{{ $t('common.language') }}</b> <i class="fas fa-chevron-down"></i>
                    </div>
                    <div class="language-options d-flex flex-column" :class="{'d-none' : !showLanguageOptions}">
                        <a class="locale-nl" @click.stop="switchLanguage('nl')">Nederlands</a>
                        <a class="locale-en" @click.stop="switchLanguage('en')">English</a>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import CreaalLogo from '@/components/CreaalLogo.vue'
import { setI18nLocale } from '@/i18n'

export default {
    components: {
        CreaalLogo,
    },
    data() {
        return {
            showNavbar: false,
            showLanguageOptions: false,
        }
    },
    methods: {
        toggleNavbar() {
            this.showNavbar = !this.showNavbar
            this.showLanguageOptions = true
        },
        toggleLanguage() {
            this.showLanguageOptions = !this.showLanguageOptions
        },

        onSwipeNavbar(direction) {
            if(this.showNavbar) {
                if(direction == 'left') {
                    this.showLanguageOptions = true
                } else if(direction == 'right') {
                    this.showLanguageOptions = false
                }
            }
        },

        switchLanguage(locale) {
            setI18nLocale(locale)
            this.showLanguageOptions = false
        }
    },
    watch: {
        '$route' () {
            if(this.showNavbar){
                this.showNavbar = true;
            }
        }
    }
}
</script>

<style>
.top-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
    background-color: #9f8053;
    z-index: -1;
}

/* Hoogte van de top navbar zetten */
.top-header .top-header-phone { height: 38px; }
.top-header .top-header-nav { 
    height: 70px; 
}

.top-header .top-header-nav .navbar-toggle .main-menu a {
    padding-top: 22px;
}
header a {
    text-decoration: none;
    color: #1e154a;
    font-size: 18px;
}
.main-menu a {
    font-weight: 600;
    font-size: 16px;
}
header a.phonenumber:hover,
header a.phonenumber-icon:hover {
    color: #fff;
}
header a:hover,
header a.router-link-active {
    color: #9f8053;
}

@media (min-width: 768px) {
    header {
        height: auto;
    }
    header .logo {
        margin: 67px 0 83px 0;
    }
}

@media (max-width: 767px) {
    header {
        height: auto;
    }
    header .logo {
        height: 46px;
        width: auto;
        margin: 32px 0 32px 0;
    }
}

.show-language-options .language-options a:hover,
html[lang=nl] .show-language-options .language-options a.locale-nl,
html[lang=de] .show-language-options .language-options a.locale-de,
html[lang=en] .show-language-options .language-options a.locale-en {
    color: #9f8053;
    cursor: pointer;
}
.topbar {
    height: 34px;
    background-color: #9f8053;
}
.phonenumber,
.phonenumber-icon {
    color: #fff;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 600;
    margin-right: 5px;
}
.top-header-nav a,
.main-menu-translate {
    margin-top: 25px;
    font-weight: 600;
    font-size: 16px;
    margin-right: 25px;
}

.main-menu-translate {
    float: right;
}

.navbar-toggler {
    width:35px;
    height:30px;
    display: block;
    position: relative;
    cursor: pointer;		
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.navbar-toggler:focus {	
    outline:none;
}
.navbar-toggler span,
.navbar-toggler span::before,
.navbar-toggler span::after {
    -webkit-transition: 250ms all;
    transition: 250ms all;
    background: #fff;
    width: 20px;
    height: 2px;
    position: absolute;
    left:7px;
}
.navbar-toggler span::before,
.navbar-toggler span::after {
    top: 0px;
    left: 0px;
    content: ' ';
    display: block;
}
.navbar-toggler span::before {
    -webkit-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    transform: translateY(-7px);
}
.navbar-toggler span::after {
    -webkit-transform: translateY(7px);
    -ms-transform: translateY(7px);
    transform: translateY(7px);
}
.navbar-toggler[aria-expanded=true] span {
    background: transparent;
}
.navbar-toggler[aria-expanded=true] span::before {
    -webkit-transform: translateY(0px) rotate(-45deg);
    -ms-transform: translateY(0px) rotate(-45deg);
    transform: translateY(0px) rotate(-45deg);
}
.navbar-toggler[aria-expanded=true] span::after {
    -webkit-transform: translateY(0px) rotate(45deg);
    -ms-transform: translateY(0px) rotate(45deg);
    transform: translateY(0px) rotate(45deg);
}

.language-options {
    display: block;
    position: absolute;
    z-index: 4;
    width: 130px;
    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
}

.language-options a {
    margin: 10px;
}

/*  vanaf 992px normaal menu  */
@media (max-width: 991px) {
    .language-options {
        /* width: 100%; */
    }
}

</style>